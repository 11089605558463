.aboutContainer {

  padding: 0 0 10rem 0;
  background: linear-gradient(180deg, rgba(212, 161, 255, 0.18109250536151966) 0%, rgba(212, 161, 255, 0) 100%);

}

.headShot {

  min-width: 238px;
  width: 20vw;
  max-width: 350px;
  /* margin left to match project spacing gaps */
  margin-left: 20px;
  filter: grayscale(1) brightness(1.3);
  -webkit-filter: grayscale(1) brightness(1.3);

}



@media (max-width: 600px) {


  .landingTextBox {


    max-width: 298px;


  }

  .headShot {
    margin-top: 3rem;
    margin-left: 0rem;
  }


}

.collapse {}