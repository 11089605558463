/* this is 800px to 1000px */
.container {
  height: 697px;
  background-image: url("../../images/cut1001.svg");
  background-repeat: no-repeat;

  background-position-x: calc(506 / 1001 * 100%);

}



@media (max-width: 600px) {
  .container {
    height: 910px;

    background-image: url("../../images/mobileCode_4.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: calc(268 / 536 * 100%);
  }

  .landingTextBox {

    max-width: 298px !important;

  }



}

@media (min-width: 600px) and (max-width: 800px) {
  .container {
    height: 623px;


    background-image: url("../../images/cut850.svg");
    background-repeat: no-repeat;
    background-position-x: calc(430 / 852 * 100%);

  }


}

@media (min-width: 1001px) and (max-width: 1279px) {
  .container {
    height: 839px;


    background-image: url("../../images/cut1280_2.svg");
    background-repeat: no-repeat;

    background-position-x: calc(648 / 1282 * 100%);
  }


}

@media (min-width: 1280px) and (max-width: 1650px) {
  .container {
    height: 1020px;


    background-image: url("../../images/cut1650_3_3.svg");
    background-repeat: no-repeat;

    background-position-x: calc(834 / 1652 * 100%);
  }


}

@media (min-width: 1650px) {
  .container {
    height: 1082px;

    background-image: url("../../images/cut1920_2.svg");
    background-repeat: no-repeat;

    background-position-x: calc(963 / 1938 * 100%);
  }


}