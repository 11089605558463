.logos {
  height: 30px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  margin-bottom: 1rem;
}



.siteImg {
  width: 100%;
  max-width: 720px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, .15);

}

.projectGroup {
  margin: 4rem 0 !important;
}

@media (max-width: 960px) {
  .projectsContainer img {
    margin: 2rem 0 2rem 0;
  }
}