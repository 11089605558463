@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap);


body{
    scroll-behavior: smooth;
}

.linkStyles{
    cursor: pointer;
    transition: all 500ms;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -ms-transition: all 500ms;
    -o-transition: all 500ms;
    
}




.linkStyles:hover {
    color:#7a04dd ;
    
  
}
@media (max-width: 600px) {


  .Header_landingTextBox__1-NA5 {

    max-width: 298px !important;

  }
}

.Header_logo__3Xwkb {
  position: absolute;
  top: 1rem;
  left: 2rem;
}

@media(max-width: 600px) {
  .Header_logo__3Xwkb {
    top: 1.5rem;
    left: 2rem;


  }
}



.Header_rightMenu__2FyWd {
  margin-right: 2rem !important;
}

.Header_fab__3QxwT {
  position: fixed !important;
  top: 2rem;
  right: 2rem;
  z-index: 1000 !important;
}

.Header_nav__3g80T {
  position: absolute !important;
  top: 0;
  max-width: 1920px !important;
  overflow-x: hidden !important;
}
/* this is 800px to 1000px */
.Landing_container__20jMe {
  height: 697px;
  background-image: url(/static/media/cut1001.10fc17c2.svg);
  background-repeat: no-repeat;

  background-position-x: calc(506 / 1001 * 100%);

}



@media (max-width: 600px) {
  .Landing_container__20jMe {
    height: 910px;

    background-image: url(/static/media/mobileCode_4.3ceb311f.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: calc(268 / 536 * 100%);
  }

  .Landing_landingTextBox__23Sd1 {

    max-width: 298px !important;

  }



}

@media (min-width: 600px) and (max-width: 800px) {
  .Landing_container__20jMe {
    height: 623px;


    background-image: url(/static/media/cut850.2a861790.svg);
    background-repeat: no-repeat;
    background-position-x: calc(430 / 852 * 100%);

  }


}

@media (min-width: 1001px) and (max-width: 1279px) {
  .Landing_container__20jMe {
    height: 839px;


    background-image: url(/static/media/cut1280_2.bab8faff.svg);
    background-repeat: no-repeat;

    background-position-x: calc(648 / 1282 * 100%);
  }


}

@media (min-width: 1280px) and (max-width: 1650px) {
  .Landing_container__20jMe {
    height: 1020px;


    background-image: url(/static/media/cut1650_3_3.f39b5303.svg);
    background-repeat: no-repeat;

    background-position-x: calc(834 / 1652 * 100%);
  }


}

@media (min-width: 1650px) {
  .Landing_container__20jMe {
    height: 1082px;

    background-image: url(/static/media/cut1920_2.11de289c.svg);
    background-repeat: no-repeat;

    background-position-x: calc(963 / 1938 * 100%);
  }


}
.Projects_logos__2ougc {
  height: 30px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  margin-bottom: 1rem;
}



.Projects_siteImg__206yU {
  width: 100%;
  max-width: 720px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, .15);

}

.Projects_projectGroup__3goXn {
  margin: 4rem 0 !important;
}

@media (max-width: 960px) {
  .Projects_projectsContainer__1EphQ img {
    margin: 2rem 0 2rem 0;
  }
}
.About_aboutContainer__2Qsmr {

  padding: 0 0 10rem 0;
  background: linear-gradient(180deg, rgba(212, 161, 255, 0.18109250536151966) 0%, rgba(212, 161, 255, 0) 100%);

}

.About_headShot__m2Mre {

  min-width: 238px;
  width: 20vw;
  max-width: 350px;
  /* margin left to match project spacing gaps */
  margin-left: 20px;
  filter: grayscale(1) brightness(1.3);
  -webkit-filter: grayscale(1) brightness(1.3);

}



@media (max-width: 600px) {


  .About_landingTextBox__2BPAE {


    max-width: 298px;


  }

  .About_headShot__m2Mre {
    margin-top: 3rem;
    margin-left: 0rem;
  }


}

.About_collapse__UiudP {}
.Contact_contactContainer__1hUGL {
  margin: 10vw 0 -29vw 0;

  min-height: 300px;
  max-width: 1920px;

}

.Contact_triangle__3UEuH {

  width: 0;

  position: absolute;

  z-index: -1;
  height: 0;

  border-left: 50vw solid white;
  border-right: 50vw solid white;
  border-bottom: 32vw solid rgba(212, 161, 255, 18%);

}

@media (min-width: 1920px) {

  .Contact_triangle__3UEuH {

    width: 0;

    height: 0;
    border-bottom: 500px solid rgba(212, 161, 255, 18%);
    border-left: 960px solid white;
    border-right: 960px solid white;
    /* border-bottom: 250px solid rgba(212, 161, 255, 18%); */

  }

  .Contact_contactContainer__1hUGL {
    margin-bottom: -475px;
  }
}

