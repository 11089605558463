@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');


body{
    scroll-behavior: smooth;
}

.linkStyles{
    cursor: pointer;
    transition: all 500ms;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -ms-transition: all 500ms;
    -o-transition: all 500ms;
    
}




.linkStyles:hover {
    color:#7a04dd ;
    
  
}